<template>
  <div class="wrap-dashboard">
    <div class="main-content">
      <div class="wrap-content">
        <div class="title-page">Withdraw</div>
        <div class="filter">
          <div>
            <div class="wrap-input">
              <label class="label-input">Depositor</label>
              <select>
                <option selected value="all">ALL</option>
                <option value="lp1">LP1</option>
                <option value="lp2">LP2</option>
                <option value="lp3">LP3</option>
              </select>
            </div>
            <div class="wrap-input">
              <label class="label-input">Total Weight</label>
              <span>gr</span>
            </div>
          </div>
          <div>
            <div class="wrap-input">
              <label class="label-input">Periode</label>
              <select>
                <option selected value="all">Year</option>
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
                <option value="daily">Daily</option>
              </select>
            </div>
            <div class="wrap-input">
              <label class="label-input">Metal Type</label>
              <span>Gold</span>
            </div>
          </div>
          <div>
            <div class="wrap-input">
              <label class="label-input">Trading Contract</label>
              <select>
                <option value="ALL">ALL</option>
                <option value="0.01gr">KAU 0.01gr</option>
                <option value="1gr">1gr</option>
              </select>
            </div>
            <div class="wrap-input">
              <label class="label-input"></label>
            </div>
          </div>
          <div>
            <div class="wrap-input">
              <label class="label-input">Allocated</label>
              <select>
                <option selected value="all">ALL</option>
                <option value="pool">Pool</option>
                <option value="non pool">Non Pool</option>
              </select>
            </div>
            <div class="wrap-input">
              <label class="label-input">Hub</label>
              <span>Jakarta</span>
            </div>
          </div>
        </div>
        <div class="content">
          <!-- <b-button
            id="show-btn"
            @click="$bvModal.show('bv-modal-example')"
            class="buuton-add"
            >Add Withdraw</b-button
          > -->
          <!-- <b-modal id="modal-1" title="Add Withdrawl"> </b-modal> -->
          <b-modal id="bv-modal-example" hide-footer>
            <template #modal-title> Add Withdraw </template>
            <div class="d-block">
              <form action="">
                <div class="form-group">
                  <label>Depositor</label>
                  <select class="select-input">
                    <!-- <option value="ALL"> Choose Depositor</option> -->
                    <option value="ALL">ALL</option>
                    <option value="ALL">LP1</option>
                    <option value="ALL">LP2</option>
                    <option value="ALL">LP3</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Activity</label>
                  <select class="select-input">
                    <!-- <option value="ALL"> Choose Activity</option> -->
                    <option value="ALL">Deposit</option>
                    <option value="ALL">Withdraw</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Trading Contract</label>
                  <select class="select-input">
                    <!-- <option value="ALL"> Choose TRading Contract</option> -->
                    <option value="ALL">ALL</option>
                    <option value="ALL">KAU 0.01gr</option>
                    <option value="ALL">1gr</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Allocated</label>
                  <select class="select-input">
                    <!-- <option value="ALL"> Choose TRading Contract</option> -->
                    <option value="ALL">ALL</option>
                    <option value="ALL">Pool</option>
                    <option value="ALL">Non Pool</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Deposit Date</label>
                  <input type="date" name="" id="" />
                </div>
                <div class="form-group">
                  <label>Dimension</label>
                  <input type="text" name="" id="" />
                </div>
                <div class="form-group">
                  <label>Fineness</label>
                  <input type="text" name="" id="" />
                </div>
                <div class="form-group">
                  <label>Hallmark</label>
                  <input type="text" name="" id="" />
                </div>
                <div class="form-group">
                  <label>Weight</label>
                  <input type="text" name="" id="" />
                </div>
                <div class="form-group">
                  <label>Serial Number</label>
                  <input type="text" name="" id="" />
                </div>
                <div class="form-group">
                  <label>Year</label>
                  <input type="text" name="" id="" />
                </div>
              </form>
            </div>
            <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Submit</b-button>
          </b-modal>
          <table>
            <thead>
              <tr id="top">
                <!-- <th scope="col" rowspan="2" style="width: 70px">No</th>
                <th scope="col" rowspan="2" style="width: 120px">Deposit Date</th>
                <th scope="col" rowspan="2">Dimension (mm<sup>3</sup>)</th>
                <th scope="col" rowspan="2">Fineness</th>
                <th scope="col" rowspan="2">Hallmark</th>
                <th scope="col" colspan="2">Description</th>
                <th scope="col" rowspan="2">Status</th>
                <th scope="col" rowspan="2">Action</th>
                <th scope="col" rowspan="2">Weight (gr)</th> -->
                <th scope="col">NO</th>
                <th scope="col">Description</th>
                <th scope="col">HIN</th>
                <th scope="col">Total Item</th>
                <th scope="col">Total Weight</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
              <!-- <tr>
                <th scope="col">Serial Number</th>
                <th scope="col">Year</th>
              </tr> -->
            </thead>
            <tbody>
              <!-- <tr v-for="(data, i) in dataTransaction" :key="i" v-show="dataTransaction.length != 0">
                <td data-label="No">{{ i + 1 }}</td>
                <td data-label="Deposite Date">{{ data.bullion_date | formatDateTime }}</td>
                <td data-label="Dimension">{{ data.dimension }}</td>
                <td data-label="Fineness">{{ data.fineness }}</td>
                <td data-label="Hallmark">{{ data.hallmark }}</td>
                <td data-label="Weight">{{ data.weight }}</td>
                <td data-label="Status">
                  <span v-show="data.status == 1">Request Withdrawal</span>
                  <span v-show="data.status == 2">Input Dimension etc</span>
                  <span v-show="data.status == 3">Clearing Confirmation</span>
                  <span v-show="data.status == 4">Operator Confirmation</span>
                  <span v-show="data.status == 5">Handover Convfirmation</span>
                  <span v-show="data.status == 1">Delivered</span>
                </td>
                <td data-label="Serial Number">{{ data.serial_number }}</td>
                <td data-label="Year">{{ data.year }}</td>
                <td data-label="Action">
                  <b-button variant="outline-danger" size="sm" class="mb-1">Decline</b-button>
                  <b-button variant="outline-success" size="sm">Approve</b-button>
                </td>
              </tr> -->
              <tr v-for="(data, i) in dataTransaction" :key="i" v-show="dataTransaction.length != 0">
                <td data-label="No">{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                <td data-label="Description">{{ data.description }}</td>
                <td data-label="Location Id">{{ data.hin_abx }}</td>
                <td data-label="Total Item">{{ data.total_item }}</td>
                <td data-label="Total Weight">{{ data.total_weight }}</td>
                <td data-label="Status">
                  <span v-show="data.status == '001'" class="status-withdrawal request">Processing</span>
                  <span v-show="data.status == '002'" class="status-withdrawal abx">Processed</span>
                  <span v-show="data.status == '003'" class="status-withdrawal operator">Vault Confirmed</span>
                  <span v-show="data.status == '004'" class="status-withdrawal kbi">Clearing Confirmed</span>
                  <span v-show="data.status == '005'" class="status-withdrawal shipping">Shipping Confirmed</span>
                  <span v-show="data.status == '006'" class="status-withdrawal handover">Handover Confirmation</span>
                  <span v-show="data.status == '007'" class="status-withdrawal kprk">KPRK Recieved</span>
                  <span v-show="data.status == '008'" class="status-withdrawal nasabah">Delivered</span>
                  <span v-show="data.status == '009'" class="status-withdrawal decline">Declined By Vault Admin</span>
                </td>
                <td data-label="Action">
                  <router-link :to="'/operator-detail-withdrawal/' + data._id">
                    <b-button variant="outline-info" size="sm" class="mb-1">Detail</b-button>
                  </router-link>
                  <!-- <b-button variant="outline-danger" size="sm" class="mb-1">Decline</b-button>
                  <b-button variant="outline-success" size="sm">Approve</b-button> -->
                </td>
              </tr>
              <tr v-show="dataTransaction.length == 0">
                <td colspan="7">No Data Available</td>
              </tr>
            </tbody>
          </table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lengthData"
            :per-page="perPage"
            @change="handlePageChange"
            aria-controls="new-table"
            first-number
            last-number
            v-show="lengthData > 10"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      dataTransaction: [],
      pageOfItems: [],
      currentPage: 1,
      perPage: 10,
      lengthData: 1,
      trade_id: [],
      offset: 0,
      data: [],
      form: {
        startDate: "",
        endDate: "",
      },
    };
  },
  mounted() {
    this.getData(this.currentPage);
  },
  methods: {
    handlePageChange(value) {
      this.getData(value);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    filterDate() {},
    getData(value) {
      let offset = (value - 1) * this.perPage;
      axios.get("/v1/clearing/operator/get/list/bullion?type=withdrawal&startDate&endDatelimit=10&offset=" + offset).then((res) => {
        this.lengthData = res.data.data.count;
        this.dataTransaction = res.data.data.data;
      });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
  computed: {
    rows() {
      return (this.currentPage - 1) * this.perPage;
    },
  },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.title-page {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 40px;
}

label {
  width: 50%;
}

.select-input,
input {
  width: 100%;
}

.form-group {
  display: flex;
}

.select-input,
input {
  border-radius: 5px;
  border: 1px solid #454545;
  padding: 0 10px;
  font-size: 13px;
}

.buuton-add {
  text-align: right;
  font-size: 13px;
}

/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.content {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.icon-trade {
  width: 25px;
}

.footer {
  background: var(--primary-color);
  color: white;
  padding: 20px 47px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  height: 62px;
}

.filter {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 12px;
  flex-wrap: wrap;
}

.wrap-input {
  margin-bottom: 10px;
}
.label-input {
  width: 109px;
}
select {
  width: 130px;
  border-radius: 3px;
  border-color: #a5a5a5a5;
  padding: 3px;
}

.card {
  width: 314px;
  font-size: 14px;
  border-radius: 10px;
}

.card-header {
  padding: 1rem;
}

.buyer,
.seller,
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 12px;
  position: relative;
  left: 20px;
}

.title::before {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--scondary-color);
  left: -17px;
  top: 3px;
}

.buyer {
  margin-bottom: 20px;
}

.content-right {
  text-align: right;
}

.amount {
  font-weight: bold;
}

.btn-primer {
  background: var(--scondary-color);
  border: none;
  color: #ffffff;
  padding: 5px 25px;
  border-radius: 5px;
}

.btn-primer:hover {
  background-color: var(--scondary-color-hover);
}

.status-withdrawal {
  padding: 5px;

  width: 20%;
  display: inherit;
}

.request {
  /* background: rgba(252, 108, 4, 0.3); */
  font-weight: 600;
  color: rgb(252, 108, 4);
  border-radius: 5px;
}
.abx {
  /* background: rgba(172, 143, 26, 0.3); */
  font-weight: 600;
  color: rgb(172, 143, 26);
  border-radius: 5px;
}
.operator {
  /* background: rgba(89, 37, 25, 0.3); */
  font-weight: 600;
  color: rgb(89, 37, 25);
  border-radius: 5px;
}
.kbi {
  /* background: rgba(60, 60, 92, 0.3); */
  font-weight: 600;
  color: rgb(60, 60, 92);
  border-radius: 5px;
}
.shipping {
  /* background: rgba(188, 180, 179, 0.3); */
  font-weight: 600;
  color: rgb(188, 180, 179);
  border-radius: 5px;
}
.handover {
  /* background: rgba(50, 90, 115, 0.3); */
  font-weight: 600;
  color: rgb(50, 90, 115);
  border-radius: 5px;
}
.kprk {
  /* background: rgba(252, 108, 4, 0.3); */
  font-weight: 600;
  color: rgb(252, 108, 4);
  border-radius: 5px;
}
.nasabah {
  /* background: rgba(40, 167, 69, 0.3); */
  font-weight: 600;
  color: rgb(40, 167, 69);
  border-radius: 5px;
}
.decline {
  /* background: rgba(220, 53, 69, 0.3); */
  font-weight: 600;
  color: rgb(220, 53, 69);
  border-radius: 5px;
}
</style>
